import { NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { ScullyRoute, ScullyRoutesService } from '@scullyio/ng-lib';
import { map, Observable } from 'rxjs';
import { MAX_DESC_CHARS_POST_CARD } from '../app.config';
import { BlogPostCardComponent } from '../shared/blog-post-card/blog-post-card.component';

@Component({
  selector: 'dev-blog-post-cards',
  templateUrl: './blog-post-cards.component.html',
  styleUrls: ['./blog-post-cards.component.scss'],
  standalone: true,
  imports: [NgFor, BlogPostCardComponent, MatAnchor, RouterLink, MatIcon, AsyncPipe, DatePipe],
})
export class BlogPostCardsComponent {
  readonly maxDescChars = inject(MAX_DESC_CHARS_POST_CARD);
  private readonly scully = inject(ScullyRoutesService);
  blogPosts: Observable<ScullyRoute[]>;
  private blogPostsLimit = 3;

  constructor() {
    // Return recent articles
    this.blogPosts = this.scully.available$.pipe(
      map((routeList: ScullyRoute[]) => {
        const blogPosts = routeList.filter((scullyRoute: ScullyRoute) => scullyRoute.route.startsWith('/blog/'));
        const latestBlogPosts = [...blogPosts].reverse().slice(0, this.blogPostsLimit);
        return [...latestBlogPosts];
      }),
    );
  }
}
