/*
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
import { NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { AnalyticsService } from '../analytics.service';
import { BlogPostCardsComponent } from '../blog-post-cards/blog-post-cards.component';
import { NavService } from '../nav.service';
import { ContactUsButtonComponent } from '../shared/contact-us-button/contact-us-button.component';
import { TechnologyCardsComponent } from '../technology-cards/technology-cards.component';

@Component({
  selector: 'dev-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  standalone: true,
  imports: [
    ContactUsButtonComponent,
    NgOptimizedImage,
    MatIcon,
    MatDivider,
    TechnologyCardsComponent,
    MatAnchor,
    RouterLink,
    BlogPostCardsComponent,
  ],
})
export class AboutComponent implements OnInit {
  route = this.router.url;

  constructor(
    private readonly navService: NavService,
    public readonly analyticsService: AnalyticsService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.navService.applyRouteMetadata('/');
  }
}
