<!--
  ~ Copyright 2013-2017 DevIntent - All Rights Reserved
  ~
  ~ Unauthorized copying of this file, via any medium is strictly prohibited without the express permission of DevIntent.
  ~
  ~ No warranty, explicit or implicit, provided.
  ~ In no event shall DevIntent be liable for any claim or damages related to this code.
  -->
<div class="container">
  <h1>Resource Not Found</h1>
  <div id="container">
    <a [routerLink]="['']"><button>Click here</button></a> to go to the home page.
  </div>
</div>
