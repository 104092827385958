import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, untracked } from '@angular/core';
import {
  MatCard,
  MatCardImage,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
  MatCardContent,
  MatCardActions,
  MatCardFooter,
} from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { ScullyRoute } from '@scullyio/ng-lib';

@Component({
  selector: 'dev-blog-post-card',
  templateUrl: './blog-post-card.component.html',
  styleUrls: ['./blog-post-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatCard,
    RouterLink,
    MatCardImage,
    NgFor,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    MatCardContent,
    MatCardActions,
    MatCardFooter,
  ],
})
export class BlogPostCardComponent {
  readonly maxDescChars = input.required<number>();
  readonly post = input.required<ScullyRoute>();

  readonly truncatedDesc = computed(() =>
    untracked(() =>
      this.post().description.length > this.maxDescChars()
        ? `${this.post().description.substring(0, this.maxDescChars())}...`
        : this.post().description,
    ),
  );

  trackByFn(index: number): number {
    return index;
  }
}
