import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { AnalyticsService } from 'src/app/analytics.service';

@Component({
  selector: 'dev-contact-us',
  templateUrl: './contact-us-button.component.html',
  standalone: true,
  imports: [NgIf, MatAnchor, RouterLink],
})
export class ContactUsButtonComponent {
  @Input() route: string | undefined;
  @Input() label = 'Contact Us';
  @Input() cssClass: string;
  @Input() buttonType: 'raised' | 'stroked' = 'raised';
  @Input() color: 'primary' | 'accent' | null = null;
  constructor(public analytics: AnalyticsService) {}
}
