<div class="technology-cards-container">
  <ng-container *ngFor="let technology of technologies">
    <mat-card class="technology-card" [routerLink]="technology.route" [ngStyle]="{ cursor: technology.route ? 'pointer' : 'initial' }">
      <mat-card-header>
        <div mat-card-avatar>
          <img class="lazyload" src="/assets/icons/{{ technology.icon }}.svg" alt="{{ technology.name }} Icon" />
        </div>
        <mat-card-title
          ><h3>{{ technology.name }}</h3></mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <p>{{ technology.description }}</p>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>
