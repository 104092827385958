/*
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
import { Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AboutComponent,
    title: 'DevIntent',
  },
  {
    path: 'angularjs-services',
    loadComponent: () => import('./angularjs/angularjs-landing/angularjs-landing.component').then(c => c.AngularjsLandingComponent),
    title: 'AngularJS Services',
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.routes').then(routes => routes.blogRoutes),
    title: 'DevIntent Blog',
  },
  {
    path: 'team',
    loadComponent: () => import('./team/team.component').then(c => c.TeamComponent),
    title: 'DevIntent Team',
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-form/contact.routes').then(routes => routes.contactRoutes),
    title: 'Contact Us - DevIntent',
  },
  {
    path: 'careers',
    loadChildren: () => import('./careers/careers.routes').then(routes => routes.careersRoutes),
    title: 'Careers - DevIntent',
  },
  { path: '404', component: PageNotFoundComponent, title: 'Page not found' },
  { path: '**', component: PageNotFoundComponent, title: 'Page not found' },
];
