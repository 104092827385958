<!--
  ~ Copyright 2020 DevIntent - All Rights Reserved
  ~
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ without the express permission of DevIntent.
  ~
  ~ No warranty, explicit or implicit, provided.
  ~ In no event shall DevIntent be liable for any claim or damages related to this code.
  -->
<main>
  <section class="section" id="hero">
    <div class="section-container">
      <div class="hero-headers">
        <h1 class="hero-title">World-Class Web Application Support</h1>
        <p class="hero-description">
          Our expert team can help you build the applications that drive your business without slowing you down.
        </p>
        <div class="hero-button-container">
          <dev-contact-us label="GET STARTED" cssClass="get-started-button" [route]="route"> </dev-contact-us>
        </div>
      </div>
      <div class="hero-image">
        <img ngSrc="../../assets/devintent-icon.svg" width="237" height="265" alt="DevIntent Icon" priority />
      </div>
    </div>
  </section>
  <section class="section" id="how-we-can-help">
    <div class="section-container">
      <div class="headers">
        <h2 class="title">How We Can Help</h2>
        <p class="description">
          Our services are highly flexible to get your team precisely the support you need with no time or money wasted on the things you
          don’t.
        </p>
      </div>
      <div class="how-we-can-help-container">
        <div class="item">
          <span class="vignette"><mat-icon>arrow_right</mat-icon></span>
          <div class="content">
            <h4 class="mat-subtitle-2">Updates and Migrations</h4>
            <p class="mat-body">
              Our team is poised to help you bring your legacy web apps into the future, without stalling your product roadmap.
            </p>
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="item">
          <span class="vignette"><mat-icon>arrow_right</mat-icon></span>
          <div class="content">
            <h4 class="mat-subtitle-2">Product Development</h4>
            <p>Need something polished? We can augment your existing engineering team or tackle the project solo.</p>
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="item">
          <span class="vignette"><mat-icon>arrow_right</mat-icon></span>
          <div class="content">
            <h4 class="mat-subtitle-2">Technology and Architecture Consulting</h4>
            <p>Choose the best tech and tools to build applications to suit your business’ goals.</p>
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="item">
          <span class="vignette"><mat-icon>arrow_right</mat-icon></span>
          <div class="content">
            <h4 class="mat-subtitle-2">Product Enhancement</h4>
            <p>Update your existing web applications to serve your business’ goals today.</p>
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="item">
          <span class="vignette"><mat-icon>arrow_right</mat-icon></span>
          <div class="content">
            <h4 class="mat-subtitle-2">MVP Implementation</h4>
            <p>Accelerate product launch, so you can get out of the weeds and focus on strategy.</p>
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="item">
          <span class="vignette"><mat-icon>arrow_right</mat-icon></span>
          <div class="content">
            <h4 class="mat-subtitle-2">Design and Performance Reviews</h4>
            <p>We’ll help ensure your applications are frictionless for your users and customers.</p>
            <mat-divider></mat-divider>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section" id="what-we-do-best">
    <div class="section-container">
      <div class="headers">
        <h2 class="title">What We Do Best</h2>
        <p class="description">
          Curious about a technology or library that isn’t listed here? Reach out, we’re always excited to talk about how we can help!
        </p>
        <dev-contact-us label="GET STARTED" cssClass="get-started-button" [route]="route"> </dev-contact-us>
      </div>
      <dev-technology-cards></dev-technology-cards>
    </div>
  </section>
  <section class="section" id="world-renowned-experts">
    <div class="section-container">
      <div class="world-renowned-experts-container">
        <div class="headers">
          <h2 class="title">World-Renowned Experts</h2>
          <p class="description">
            Our consultants are development and architecture experts who are extremely active in the Angular community. Some of them have
            been on the Angular team itself. You’re in good hands!
          </p>
          <a mat-stroked-button routerLink="team" id="meet-the-team-button" color="primary">
            <span>MEET THE TEAM</span>
          </a>
        </div>
        <div class="world-renowned-experts-image">
          <img
            ngSrc="../../assets/illustrations/trophy-illustration.svg"
            width="450"
            height="363"
            alt="An indigo trophy with a green medal attached to it and green stars around it"
          />
        </div>
      </div>
      <dev-blog-post-cards></dev-blog-post-cards>
    </div>
  </section>
  <section class="section" id="ready-to-get-started">
    <div class="section-container">
      <div class="headers">
        <h2 class="title">Ready to get started or not sure what you need?</h2>
      </div>
      <dev-contact-us label="REACH OUT" cssClass="reach-out-button" [route]="route"> </dev-contact-us>
    </div>
  </section>
</main>
