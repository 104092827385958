import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, InjectionToken } from '@angular/core';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, APP_NAME, APP_VERSION } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/compat/performance';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, RouterModule, withInMemoryScrolling, withPreloading } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { environment } from '../environments/environment';
import { appRoutes } from './app-routes';

export const MAX_DESC_CHARS_POST_CARD = new InjectionToken<number>('Maximum description characters for blog post card');

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireAuthModule,
      AngularFireAnalyticsModule,
      AngularFirePerformanceModule,
      ScullyLibModule,
      RouterModule.forRoot(appRoutes),
      // According to the [SW update algorithm](https://w3c.github.io/ServiceWorker/#update-algorithm)
      // from the spec, browsers do a byte-for-byte comparison of the SW script response's body (i.e.
      // ignore headers). In order to be able to pick up updated headers (for example,
      // as part of a CSP update), we need to change the requested SW script URL
      // (via a query param).
      ServiceWorkerModule.register('ngsw-worker.js?v3', {
        enabled: environment.serviceWorkerEnabled,
        registrationStrategy: 'registerWithDelay:5000',
      }),
    ]),
    // provideRouter(appRoutes),
    ScreenTrackingService,
    UserTrackingService,
    PerformanceMonitoringService,
    { provide: APP_NAME, useValue: environment.firebase.projectId },
    { provide: APP_VERSION, useValue: environment.version },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(
      appRoutes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({ scrollPositionRestoration: 'disabled', anchorScrolling: 'disabled' }),
    ),
    { provide: MAX_DESC_CHARS_POST_CARD, useValue: 97 },
    // provideMaxDescCharsPostCard(97),
  ],
};
