<a
  class="{{ cssClass }}"
  *ngIf="buttonType === 'stroked'"
  mat-stroked-button
  data-testid="contact-us-stroked-button"
  (click)="analytics.contactFormOpened(route)"
  [routerLink]="['/contact-us']"
  [color]="color"
>
  <ng-content></ng-content>
  <span> {{ label }}</span>
</a>
<a
  class="{{ cssClass }}"
  *ngIf="buttonType === 'raised'"
  mat-raised-button
  data-testid="contact-us-raised-button"
  (click)="analytics.contactFormOpened(route)"
  [routerLink]="['/contact-us']"
  [color]="color"
>
  <ng-content></ng-content>
  <span>{{ label }}</span>
</a>
